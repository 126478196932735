import axios from "axios";
import { toast } from "react-hot-toast";
import { baseUrl, getLs } from ".";

axios.defaults.baseURL = `${baseUrl}`;
axios.defaults.headers.common["Api-Token"] = getLs("billnote_api") || "";
axios.defaults.headers.common["X-Requested-With"] = `XMLHttpRequest`;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    toast.error(error.message || "Something went wrong..", {
      duration: 50000,
    });
    //console.log(error);
    // whatever you want to do with the error
    throw error;
  }
);

const Api = axios.create();

export default Api;
