import React, { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import ForgotPassword from "../pages/forgotPassword";

const loader = () => {
  return (
    <div className="text-center" style={{ marginTop: "calc(30vh)" }}>
      <Spinner animation="border" />
    </div>
  );
};

const Login = React.lazy(() => import("../pages/login"));

const Layout = React.lazy(() => import("../pages/app/layout"));

export default () => (
  <HashRouter>
    <Suspense fallback={loader()}>
      <Routes>
        <Route path="/app/*" element={<Layout />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/" element={<Login />} />
      </Routes>
    </Suspense>
  </HashRouter>
);
